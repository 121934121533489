import React from 'react'

const Rounds = () => {
    return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 33 31" fill="none">
                <path d="M30.356 17.1396C30.356 23.1775 25.4613 28.0722 19.4234 28.0722C13.3855 28.0722 8.49079 23.1775 8.49079 17.1396C8.49079 11.1017 13.3855 6.20701 19.4234 6.20701C25.4613 6.20701 30.356 11.1017 30.356 17.1396Z" fill="#FCD91E" />
                <path d="M24.2945 21.1148C20.2269 27.2524 12.1771 29.0783 6.31471 25.1931C0.452364 21.3079 -1.00253 13.1828 3.06512 7.04519C7.13276 0.907602 15.1826 -0.918298 21.045 2.96693C26.9073 6.85216 28.3622 14.9773 24.2945 21.1148Z" fill="#FCD91E" />
                <path d="M26.5913 13.0354C26.931 20.9141 20.6874 26.481 15.1526 27.072C9.21503 27.072 4.8111 20.9257 4.8111 14.9881C4.8111 9.05061 9.62443 4.23728 15.562 4.23728C21.4995 4.23728 26.5913 7.09781 26.5913 13.0354Z" fill="#F7F3F0" />
                <path d="M32.8922 17.1396C32.8922 24.5782 26.862 30.6084 19.4234 30.6084C11.9848 30.6084 5.95466 24.5782 5.95466 17.1396C5.95466 9.70104 11.9848 3.67087 19.4234 3.67087C26.862 3.67087 32.8922 9.70104 32.8922 17.1396ZM9.71325 17.1396C9.71325 22.5024 14.0606 26.8498 19.4234 26.8498C24.7862 26.8498 29.1336 22.5024 29.1336 17.1396C29.1336 11.7768 24.7862 7.42946 19.4234 7.42946C14.0606 7.42946 9.71325 11.7768 9.71325 17.1396Z" fill="#F7F3F0" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.4234 29.6084C26.3097 29.6084 31.8922 24.0259 31.8922 17.1396C31.8922 10.2533 26.3097 4.67087 19.4234 4.67087C12.5371 4.67087 6.95466 10.2533 6.95466 17.1396C6.95466 24.0259 12.5371 29.6084 19.4234 29.6084ZM19.4234 27.8498C13.5083 27.8498 8.71325 23.0547 8.71325 17.1396C8.71325 11.2246 13.5083 6.42946 19.4234 6.42946C25.3385 6.42946 30.1336 11.2246 30.1336 17.1396C30.1336 23.0547 25.3385 27.8498 19.4234 27.8498ZM19.4234 30.6084C26.862 30.6084 32.8922 24.5782 32.8922 17.1396C32.8922 9.70104 26.862 3.67087 19.4234 3.67087C11.9848 3.67087 5.95466 9.70104 5.95466 17.1396C5.95466 24.5782 11.9848 30.6084 19.4234 30.6084ZM19.4234 26.8498C14.0606 26.8498 9.71325 22.5024 9.71325 17.1396C9.71325 11.7768 14.0606 7.42946 19.4234 7.42946C24.7862 7.42946 29.1336 11.7768 29.1336 17.1396C29.1336 22.5024 24.7862 26.8498 19.4234 26.8498Z" fill="black" />
            </svg>
        </div>
    )
}

export default Rounds

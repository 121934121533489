import React from 'react'

const GiftBox = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="57" viewBox="0 0 84 57" fill="none">
                <path d="M80.2238 7.95679H0.792969V56.3451H80.2238V7.95679Z" fill="white" />
                <path d="M30.293 7.95679H15.3105V56.3451H30.293V7.95679Z" fill="#28C755" />
                <path d="M80.2267 7.95679H45.4102V56.3451H80.2267V7.95679Z" fill="white" />
                <path d="M34.905 4.01073L23.2646 0.413279C22.9503 0.382097 22.6676 0.376755 22.4013 0.382101L22.2082 0.318848H10.4883L19.6209 3.34167C19.6663 3.79335 19.7724 4.25306 19.915 4.69138C20.4526 6.33598 22.6386 7.14402 24.0698 7.50573L24.1418 7.75964H25.3244C25.337 7.76142 25.3559 7.76409 25.3559 7.76409L45.6355 7.95653C45.6355 7.95653 40.4318 5.98318 34.905 4.01073Z" fill="#28C755" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M33.9866 7.95746C33.9866 7.95746 21.3238 3.15729 14.5223 1.04497C7.7221 -1.06736 7.44949 2.1969 8.26607 4.6932C9.08266 7.1895 13.707 7.76502 13.707 7.76502L33.9866 7.95746Z" fill="#28C755" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M36.9789 4.45361H32.5199C29.8467 4.45361 27.6797 5.98329 27.6797 7.87021H41.8191C41.8204 5.98418 39.6533 4.45361 36.9789 4.45361Z" fill="#28C755" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M64.8279 0.413245H54.5291L53.1875 4.0107C47.6607 5.98315 42.457 7.9556 42.457 7.9556L62.7366 7.76316C62.7366 7.76316 62.7555 7.76049 62.7681 7.75871H63.9507L64.0227 7.5048C65.4552 7.1431 67.6399 6.33416 68.1775 4.69045C68.8553 2.61822 68.7695 0.0248133 64.8279 0.413245Z" fill="#28C755" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M30.2969 7.95697C30.2969 7.95697 42.9596 3.1568 49.7611 1.04448C56.5613 -1.06785 56.834 2.19641 56.0174 4.69271C55.2008 7.18901 50.5764 7.76453 50.5764 7.76453L30.2969 7.95697Z" fill="#28C755" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M83.1183 7.95532H3.6875V56.3436H83.1183V7.95532Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M33.1876 7.95581H18.2051V56.3441H33.1876V7.95581Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M83.1193 7.95581H48.3027V56.3441H83.1193V7.95581Z" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}

export default GiftBox

import React, { useState, useEffect, useRef } from 'react'

import '../CustomCards/style.css';
import Carousel from 'react-elastic-carousel';
import { TextField } from '@mui/material';
import Fourdots from '../assets/svg/Fourdots';

const SecurityCard = () => {


    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
    const particles = {
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        height: '2vh',
        // zIndex: 2,
        margin: "2vh",
        marginLeft: '2px',
    }


    const [data, setData] = useState([
        {
            id: "1",
            title: "Secure Transactions",
            tech: "We emphasises the security of transactions,adhering to stringent standards to protect user data and financial information.",

        }, {
            id: "2",
            title: 'Time and Effort Savings',
            tech: "Gone are the days of managing multiple accounts and due dates. pockt consolidates bills in one place, saving customers valuable time and effort.",

        },

    ])

    const offerCard = {
        borderRadius: "30px",
        // boxShadow: '0 0 5px 0 rgba(0, 131, 52, 1)',
        padding: "2%",

        margin: 0,
        // width: { lg: '30%', md: '30%', sm: '80%', xs: '80%' },
        width: "350px",
        height: '150px',
        fontFamily: 'BalooBhaijaan2-Regular',
        fontSize: { lg: '20px', md: '20px', sm: '12px', xs: '12px' },
        fontStyle: 'normal',
        lineHeight: 'normal',
        textAlign: 'center',
        border: '1px solid #232323',
        flexShrink: 0,
        // padding: 'auto',
        // overflow: 'scroll',
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#D9D9D9',
        justifyContent: 'center',
        marginRight: "2%",
        stroke: '#232323'


    }








    return (

        <>

            <div id='#scrollable-container' className="scrolling-wrapper scrollable-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>


                {data?.map((item, index) => (

                    <div key={item.id}
                    id='#scrollable-container'
                    style={{
                        borderRadius: "30px",
                        borderColor: 'rgba(217, 217, 217, 0.3)',
                        borderWidth: '3px',
                        borderStyle: 'solid',
                        margin: 0,
                        width: "350px",
                        height: '200px',
                        textAlign: 'center',
                        flexShrink: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: "2%",
                        padding: '15px',
                        flexWrap:'wrap'
                    }} className="scrollable-container" >

                        <p style={{
                             fontFamily: "BalooBhaijaan2-Bold",
                             fontSize: { lg: "25px", md: "25px", sm: "16px", xs: '16px' },
                             fontStyle: "normal",
                             fontWeight: 600,
                             lineHeight: "normal",
                        }}>
                            {item.title}
                        </p>
                        <p style={{
                           fontFamily: "BalooBhaijaan2-Regular",
                           fontSize: { lg: "16px", md: "16px", sm: "14px", xs: '14px' },
                           fontStyle: "normal",
                           fontWeight: 600,
                           textWrap:'wrap',
                           whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
                           textAlign:'center',
                           display:'flex',
                            flexWrap:'wrap',
                        }}>
                            {item.tech}
                        </p>


                    </div>


                ))}


            </div>
            {/* <div className="scrollable-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%',padding:0 }}>
                <Carousel showArrows={false} showEmptySlots={true} style={{ gap: 0,width: '100%', }} pagination={false} currentIndex={currentCarouselIndex}
                    onChange={(index) => setCurrentCarouselIndex(index?.index)}>
                    {data.map(item => (
                        <div key={item.id} style={{
                            borderRadius: "30px",
                            borderColor: 'rgba(217, 217, 217, 0.3)',
                            borderWidth: '3px',
                            borderStyle: 'solid',
                            margin: 0,
                            width: "350px",
                            height: '150px',
                            textAlign: 'center',
                            flexShrink: 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // marginRight: "2%",
                            paddingTop: '25px',
                        }} className="scrollable-container" >

                            <p style={{
                                fontFamily: "BalooBhaijaan2-Medium",
                                fontSize: "25px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "25px",
                                whiteSpace: 'pre-wrap',
                                marginTop: 'auto',
                            }}>
                                {item.title}
                            </p>
                            <p style={{
                                fontFamily: "BalooBhaijaan2-Regular",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "25px",
                                whiteSpace: "pre"

                            }}>
                                {item.tech}
                            </p>


                        </div>

                    ))}
                </Carousel>
            </div> */}

        </>
    )
}

export default SecurityCard

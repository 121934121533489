import React from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
} from '@mui/material';

const SideNavigation = ({ selectedItem, setSelectedItem }) => {
    const navigationItems = [
        { key: 'home', label: 'Home' },
        // { key: 'blog', label: 'Blogs' },
        { key: 'faqs', label: 'FAQs' },
        { key: 'aboutus', label: 'About Us' },
        { key: 'contactus', label: 'Contact Us' },
    ];

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <List sx={{ marginLeft: 1 }}>
            {navigationItems.map((item) => (
                <a href={`#${item.key}`} style={{ textDecoration: 'none' }}>

                    <ListItem

                        key={item.key}
                        // selected={selectedItem === 'contactus'}
                        button
                        onClick={() => { handleItemClick(item.key); }}
                        sx={{ borderLeft: selectedItem === item.key && '4px solid #589CFF' }}
                    >

                        <ListItemIcon>

                            <Typography
                                style={{
                                    color: selectedItem === item.key ? '#589CFF' : "",
                                    fontFamily: selectedItem === item.key ? "BalooBhaijaan2-Bold" : 'BalooBhaijaan2-Regular',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                    textAlign: 'left',
                                }}>
                                {item.label}
                            </Typography>

                        </ListItemIcon>
                    </ListItem>
                </a>
            ))}
        </List>
    );
};

export default SideNavigation;

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import '../CustomCards/style.css';
import Carousel from 'react-elastic-carousel';
import Bluedots from '../assets/svg/Bluedots.js';
import Bluedotss from '../assets/svg/Bluedotss.js';
import Rounds from '../assets/svg/Rounds.js';
import Bluepapers from '../assets/svg/Bluepapers.js';

import Fourdots from '../assets/svg/Fourdots.js';
import GiftBox from '../assets/svg/GiftBox.js';

const StyledText = styled.p`
 
  font-size: 33px;
      
  font-style: "normal",
  font-weight: 500,
  line-height: "25px",
  text-align: 'center',
  padding: '5px'

  /* Styles for large devices (lg) */
  @media (min-width: 1200px) {
    font-size: 40px;
  }

  /* Styles for medium devices (md) */
  @media (max-width: 1199px) and (min-width: 768px) {
    font-size: 33px;
  }

  /* Styles for small devices */
  @media (max-width: 767px) {
    font-size: 25px;
  }
`;
const OfferCard = () => {


    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

    const [data, setData] = useState([
        {
            id: "1",
            info: "Pay your bills on pockt to earn exciting rewards...",
            pending: '3'
        }, {
            id: "2",
            info: "Pay your bill to get Gas offer",
            pending: '3'
        }, {
            id: "3",
            info: "Pay your bill to get Water offer",
            pending: '3'
        }, {
            id: "4",
            info: "Pay your bill to get Mobile offer",
            pending: '1'
        }
    ])

    const offerCard = {
        position: 'relative',
        // zIndex: 99,
        width: '100%',
        height: "20vh",
        borderRadius: "30px",
        // border: "2px solid rgba(44, 44, 44, 0.00)",
        // padding: '10px',

        boxShadow: ' 0px 0px 5px 0px rgba(44, 44, 44, 0), rgba(0, 131, 52, 1), rgba(44, 44, 44, 0)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: 0,
        margin: 0

    }
    const particles = {
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        height: '2vh',
        // zIndex: 2,
        margin: "2vh",
        marginLeft: '2px',
    }







    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '45%', padding: '5%' }}>
            <div style={particles}>
                <div className='shaking-svg'>
                    <Rounds />
                </div>
                <div className='shaking-svg' style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Bluedots />
                </div>
            </div>
            {/* <Carousel showArrows={false} showEmptySlots={true} style={{ gap: 0, }} pagination={false} currentIndex={currentCarouselIndex}
                onChange={(index) => setCurrentCarouselIndex(index?.index)}>
                {data.map(item => ( */}
                    <div className='gradient-border-offer-card' 
                    // key={item.id} 
                    style={offerCard}
                    >

                        <div>
                            {/* <p style={{
                                // color: "#DCDCDC",
                                fontFamily: "BalooBhaijaan2-SemiBold",
                                fontSize: ["25px", "33px", "40px"],
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "25px",
                                textAlign: 'center',
                                padding: '5px'

                            }}>

                            </p> */}
                            <StyledText style={{ fontFamily: "BalooBhaijaan2-SemiBold",   textAlign:'center'                  }}>
                                {data[0].info}
                            </StyledText>
                        </div>
                    </div>
                {/* ))} */}
            {/* </Carousel> */}
            <div style={particles}>
                <div className='shaking-svg' style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                    <Bluedotss />
                </div>


                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    {/* <Fourdots currentCarouselIndex={currentCarouselIndex} /> */}
                </div>

                <div className='shaking-svg' style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', }}>
                    <GiftBox />
                    <Bluepapers />
                </div>
            </div>
            {/* <BottomNavigation /> */}
        </div>
    )
}

export default OfferCard

import React from 'react'
import { createTheme, ThemeProvider, CssBaseline, Box, Typography, IconButton, Button, Accordion, Drawer, List, ListItem, ListItemIcon, ListItemText, AccordionSummary, AccordionDetails, Modal, Hidden } from '@mui/material';
const DownloadButton = ({setOpen}) => {
    return (
        <Button
            onClick={() => { setOpen(true) }}
            variant='contained' sx={{
                borderRadius: "71px",
                width: { lg: "338px", md: "280px%", sm: "350px", xs: "350px" },
                height: "60px",
                flexShrink: 0,
                background: "#589CFF",
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Typography sx={{
                color: "#FFF",
                // textAlign: "center",
                fontFamily: "BalooBhaijaan2-Bold",
                fontSize: { lg: "28px", md: "28px", sm: "25px", xs: '25px' },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "37px",
                height: "31px",
                flexShrink: 0,
                alignItems: 'center',
                justifyContent: 'center'

            }}>
                Download Now | <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 31 32" fill="none" style={{marginRight:'8px'}}>
                    <path d="M27.6655 23.2163C27.2166 24.2542 26.6854 25.2096 26.0698 26.088C25.2307 27.2854 24.5437 28.1143 24.0142 28.5746C23.1935 29.3301 22.3141 29.7171 21.3724 29.7391C20.6964 29.7391 19.8812 29.5465 18.9322 29.1559C17.9801 28.7672 17.1051 28.5746 16.305 28.5746C15.4659 28.5746 14.566 28.7672 13.6035 29.1559C12.6395 29.5465 11.8629 29.7501 11.2691 29.7702C10.3661 29.8087 9.46602 29.4108 8.56759 28.5746C7.99416 28.074 7.27692 27.2158 6.41769 26C5.49581 24.7016 4.73789 23.1961 4.14413 21.4797C3.50823 19.6257 3.18945 17.8304 3.18945 16.0924C3.18945 14.1014 3.61925 12.3842 4.48012 10.9453C5.1567 9.78942 6.05678 8.87766 7.1833 8.20833C8.30982 7.53899 9.52703 7.19791 10.8378 7.17609C11.5551 7.17609 12.4957 7.39816 13.6645 7.8346C14.83 8.27251 15.5784 8.49458 15.9066 8.49458C16.1519 8.49458 16.9832 8.23492 18.3926 7.71724C19.7254 7.23715 20.8503 7.03837 21.7718 7.11667C24.2689 7.31839 26.1449 8.30369 27.3925 10.0788C25.1593 11.4332 24.0545 13.3303 24.0765 15.7639C24.0967 17.6595 24.7837 19.2369 26.1339 20.4894C26.7458 21.0707 27.4292 21.52 28.1895 21.8391C28.0246 22.3177 27.8505 22.7762 27.6655 23.2163ZM21.9385 0.658789C21.9385 2.14454 21.3963 3.53179 20.3154 4.81581C19.0109 6.34226 17.4332 7.22431 15.7222 7.08513C15.7004 6.90689 15.6878 6.71929 15.6878 6.52216C15.6878 5.09583 16.3081 3.56938 17.4097 2.3213C17.9597 1.68938 18.6592 1.16394 19.5074 0.744793C20.3538 0.331896 21.1544 0.103556 21.9074 0.0644531C21.9294 0.263075 21.9385 0.46171 21.9385 0.65877V0.658789Z" fill="white" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 25" fill="none">
                    <path d="M3.89292 0.942157C3.61365 0.769717 3.3007 0.652946 2.97289 0.598869C2.64508 0.544792 2.30919 0.554526 1.98543 0.627485L13.3342 11.4525L17.0124 7.94405L3.89292 0.942157ZM0.576831 1.50742C0.260299 1.90881 0.078125 2.40165 0.078125 2.923V22.2054C0.078125 22.7268 0.260348 23.2196 0.576831 23.621L12.1686 12.5642L0.576831 1.50742ZM21.8127 10.5061L18.5071 8.74182L14.4997 12.5642L18.5073 16.3867L21.8133 14.6224C22.6053 14.1991 23.0781 13.4298 23.0781 12.5642C23.0781 11.6986 22.6053 10.9293 21.8127 10.5061ZM13.3341 13.6759L1.98606 24.5002C2.17212 24.542 2.36147 24.5645 2.55082 24.5645C3.02706 24.5644 3.49313 24.433 3.89292 24.1862L17.0125 17.1844L13.3341 13.6759Z" fill="white" />
                </svg></Typography>
        </Button>
    )
}

export default DownloadButton

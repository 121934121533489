import { refunddata } from '../assets/data/data.js'
import React, { useEffect, useState } from 'react';


const RefundPolicy = () => {

    const [dataMap, setDataMap] = useState(new Map());
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        
    }, []);
    // console.log(data)
    useEffect(() => {


        const map = new Map();
        // if (type == "Terms and Condition") {
        refunddata.forEach((item, index) => {
            const trimmedLine = item.line.trim();
            map.set(index, trimmedLine);
        });

        setDataMap(map);
    }, []);
    return (
        <div style={{display:"flex",flexDirection:'column',alignContent:'center',justifyContent:'flex-start',width:'100%',padding:'5%'}}>
            <p style={{textAlign: 'center', wordBreak: 'break-word', textAlign: 'justify', fontSize: '25px', margin: 0, padding: '10px',width:'100%'}}>Refund Policy</p>
            <ul style={{ margin: 0, padding: 0 }}>
                {Array.from(dataMap).map(([index, line]) => (
                    <p key={index} style={{ textAlign: 'left', wordBreak: 'break-word', textAlign: 'justify', fontSize: '14px', margin: 0, padding: '10px' }} >{line}</p>
                ))}
            </ul>

        </div>
    )
}

export default RefundPolicy
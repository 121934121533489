import React from 'react'

const Bluedotss = () => {
  return (
    <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
              <path d="M11.4258 6.82678C11.4258 9.62975 9.15355 11.902 6.35059 11.902C3.54762 11.902 1.27537 9.62975 1.27537 6.82678C1.27537 4.02381 3.54762 1.75156 6.35059 1.75156C9.15355 1.75156 11.4258 4.02381 11.4258 6.82678Z" fill="#5451E2" />
              <path d="M12.6032 6.82678C12.6032 10.28 9.80378 13.0793 6.35059 13.0793C2.89739 13.0793 0.0980225 10.28 0.0980225 6.82678C0.0980225 3.37359 2.89739 0.574219 6.35059 0.574219C9.80378 0.574219 12.6032 3.37359 12.6032 6.82678ZM1.84286 6.82678C1.84286 9.31633 3.86104 11.3345 6.35059 11.3345C8.84013 11.3345 10.8583 9.31633 10.8583 6.82678C10.8583 4.33724 8.84013 2.31906 6.35059 2.31906C3.86104 2.31906 1.84286 4.33724 1.84286 6.82678Z" fill="#F7F3F0" />
              <path fillRule="evenodd" clipRule="evenodd" d="M6.35059 12.6151C9.5474 12.6151 12.1389 10.0236 12.1389 6.82678C12.1389 3.62997 9.5474 1.03845 6.35059 1.03845C3.15378 1.03845 0.56225 3.62997 0.56225 6.82678C0.56225 10.0236 3.15378 12.6151 6.35059 12.6151ZM6.35059 11.7987C3.60465 11.7987 1.37864 9.57271 1.37864 6.82678C1.37864 4.08085 3.60465 1.85483 6.35059 1.85483C9.09652 1.85483 11.3225 4.08085 11.3225 6.82678C11.3225 9.57271 9.09652 11.7987 6.35059 11.7987ZM6.35059 13.0793C9.80378 13.0793 12.6032 10.28 12.6032 6.82678C12.6032 3.37359 9.80378 0.574219 6.35059 0.574219C2.89739 0.574219 0.0980225 3.37359 0.0980225 6.82678C0.0980225 10.28 2.89739 13.0793 6.35059 13.0793ZM6.35059 11.3345C3.86104 11.3345 1.84286 9.31633 1.84286 6.82678C1.84286 4.33724 3.86104 2.31906 6.35059 2.31906C8.84013 2.31906 10.8583 4.33724 10.8583 6.82678C10.8583 9.31633 8.84013 11.3345 6.35059 11.3345Z" fill="#5451E2" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
              <path d="M6.51166 3.44812C6.51166 4.84963 5.37551 5.98578 3.974 5.98578C2.57249 5.98578 1.43634 4.84963 1.43634 3.44812C1.43634 2.04661 2.57249 0.91046 3.974 0.91046C5.37551 0.91046 6.51166 2.04661 6.51166 3.44812Z" fill="#5451E2" />
              <path d="M7.10034 3.44812C7.10034 5.17475 5.70063 6.57446 3.974 6.57446C2.24737 6.57446 0.847656 5.17475 0.847656 3.44812C0.847656 1.72149 2.24737 0.321777 3.974 0.321777C5.70063 0.321777 7.10034 1.72149 7.10034 3.44812ZM1.72009 3.44812C1.72009 4.69292 2.7292 5.70203 3.974 5.70203C5.2188 5.70203 6.22791 4.69292 6.22791 3.44812C6.22791 2.20332 5.2188 1.19421 3.974 1.19421C2.7292 1.19421 1.72009 2.20332 1.72009 3.44812Z" fill="#F7F3F0" />
              <path fillRule="evenodd" clipRule="evenodd" d="M3.974 6.34234C5.57244 6.34234 6.86822 5.04656 6.86822 3.44812C6.86822 1.84968 5.57244 0.553896 3.974 0.553896C2.37556 0.553896 1.07977 1.84968 1.07977 3.44812C1.07977 5.04656 2.37556 6.34234 3.974 6.34234ZM3.974 5.93414C2.60101 5.93414 1.48798 4.82111 1.48798 3.44812C1.48798 2.07513 2.60101 0.962097 3.974 0.962097C5.34699 0.962097 6.46002 2.07513 6.46002 3.44812C6.46002 4.82111 5.34699 5.93414 3.974 5.93414ZM3.974 6.57446C5.70063 6.57446 7.10034 5.17475 7.10034 3.44812C7.10034 1.72149 5.70063 0.321777 3.974 0.321777C2.24737 0.321777 0.847656 1.72149 0.847656 3.44812C0.847656 5.17475 2.24737 6.57446 3.974 6.57446ZM3.974 5.70203C2.7292 5.70203 1.72009 4.69292 1.72009 3.44812C1.72009 2.20332 2.7292 1.19421 3.974 1.19421C5.2188 1.19421 6.22791 2.20332 6.22791 3.44812C6.22791 4.69292 5.2188 5.70203 3.974 5.70203Z" fill="#5451E2" />
            </svg>
    </div>
  )
}

export default Bluedotss

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { termsandConditionsdata, cookiedata, privacydata } from '../assets/data/data.js'
export default function TermsandCondition({ open, type, scroll, handleClosePolicy }) {




    const [dataMap, setDataMap] = useState(new Map());
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    // console.log(data)
    useEffect(() => {


        const map = new Map();
        // if (type == "Terms and Condition") {
        termsandConditionsdata.forEach((item, index) => {
            const trimmedLine = item.line.trim();
            map.set(index, trimmedLine);
        });
        // }
        // if (type == "Privacy Policy") {
        //     privacydata.forEach((item, index) => {
        //         map.set(index, item.line);
        //     });
        // }
        // if (type == "Cookie Policy") {
        //     cookiedata.forEach((item, index) => {
        //         map.set(index, item.line);
        //     });
        // }
        setDataMap(map);
    }, []);


    return (
        <Dialog
            open={open}
            fullWidth
            sx={{
                margin: 0, maxWidth: "100% !important",
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "100%",
                    },
                },
            }}


            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            
                <DialogTitle id="scroll-dialog-title">{type}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                    // tabIndex={-1}
                    >


                        <ul style={{ margin: 0, padding: 0 }}>
                            {Array.from(dataMap).map(([index, line]) => (
                                <p key={index} style={{ textAlign: 'left', wordBreak: 'break-word', textAlign: 'justify', fontSize: '14px', margin: 0, padding: '10px' }} >{line}</p>
                            ))}
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePolicy}>Cancel</Button>

                </DialogActions>
            
        </Dialog>

    );
}
import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Homescreen from './screens/Homescreen';
// import  Homescreen111  from './screens/Homescreen111';
import { createTheme, ThemeProvider, CssBaseline, Box, Typography, IconButton } from '@mui/material';
import { Brightness4 as DarkModeIcon, Brightness7 as LightModeIcon } from '@mui/icons-material';
import Header from './Header/Header';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import PrivacyPolicyscreen from './screens/PrivacyPolicyscreen';
import TermsandConditions from './screens/TermsandConditions';
import RefundPolicy from './screens/RefundPolicy';
import CookiePolicy from './screens/CookiePolicy';
function App() {
  const [darkMode, setDarkMode] = useState(true);


  const lightTheme = createTheme({

    palette: {
      // mode: 'light',
      background: '#F2F2F2'
    },
  });

  const darkTheme = createTheme({

    palette: {
      mode: 'dark',
    },
  });


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };



  return (
    <ThemeProvider theme={
      // darkMode ? 
      darkTheme
      //  : lightTheme
    }>
      <CssBaseline />

      <div style={{ width: "100%", height: '100vh', }}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Homescreen />}
            />
            <Route
              path="/privacypolicy"
              element={<PrivacyPolicyscreen />}
            />
            <Route
              path="/termsandconditions"
              element={<TermsandConditions />}
            />
            <Route
              path="/refundpolicy"
              element={<RefundPolicy />}
            />
            <Route
              path="/cookiepolicy"
              element={<CookiePolicy />}
            />

          </Routes>
        </Router>
      </div>


    </ThemeProvider>

  );
}

export default App;

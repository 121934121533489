export const termsandConditionsdata = [
    {
        line:

            `NIGHT PROGRAMMERS TECHNOLOGIES maintains the https://pocktapp.in Website ("Site"). The following are the terms of use that govern use of the Site ("Terms of Use"). By using the Site you expressly agree to be bound by these Terms of Use and the https://pocktapp.in privacy policy and to follow these Terms of Use and all applicable laws and regulations governing use of the Site. NIGHT PROGRAMMERS TECHNOLOGIES reserves the right to change these Terms of Use at any time, effective immediately upon posting on the Site. Please check this page of the Site periodically. We will note when there are updates to the Terms of Use at the bottom of the Terms of Use. If you violate these Terms of Use, NIGHT PROGRAMMERS TECHNOLOGIES may terminate your use of the Site, bar you from future use of the Site, and/or take appropriate legal action against you.`
    },
    {
        line:
            `LIMITED LICENSE. You are granted a limited, non-exclusive, revocable and non-transferable license to utilize and access the Site pursuant to the requirements and restrictions of these Terms of Use. NIGHT PROGRAMMERS TECHNOLOGIES may change, suspend, or discontinue any aspect of the Site at any time. NIGHT PROGRAMMERS TECHNOLOGIES may also, without notice or liability, impose limits on certain features and services or restrict your access to all or portions of the Site. You shall have no rights to the proprietary software and related documentation, if any, provided to you in order to access the Site. Except as provided in the Terms of Use, you shall have no right to directly or indirectly, own, use, loan, sell, rent, lease, license, sublicense, assign, copy, translate, modify, adapt, improve, or create any new or derivative works from, or display, distribute, perform, or in any way exploit the Site, or any of its contents (including software) in whole or in part.`
    },
    {
        line:
            `1. SITE OPERATION: India is our Country of Domicile. NIGHT PROGRAMMERS TECHNOLOGIES controls this Site from India and makes no representation that this Site is appropriate for use in other locations. If you use this Site from other locations you are responsible for ensuring compliance with local laws. You may not use, export or re-export any materials from this Site in violation of any applicable laws or regulations, including, but not limited to any Indian export laws and regulations.`
    },
    {
        line:
            `2. APPLICABLE LAW. The Laws of the India shall govern the use of the Site and the Terms of Use, without regards to conflict of laws principals. All disputes arising in connection therewith shall be heard only by a court of competent jurisdiction in India. `
    },
    {
        line:
            `3. MULTI-CURRENCY PRICED TRANSACTION, the displayed price and currency selected by you, will be the same price and currency charged to the Card and printed on the Transaction Receipt.`
    },
    {
        line:
            `4. PURCHASES. NIGHT PROGRAMMERS TECHNOLOGIES  accepts payment by legally accepted modes in INR for its products and services. All online purchases are also governed by the terms and conditions of respective merchant service providers. Please review respective merchant service provider’s user agreement and privacy policy before entering any transaction. Interpretation. These Terms of Use supersede all other written and oral communications or agreements with regard to the subject matters discussed in these Terms of Use. A waiver or modification of these Terms of Use will only be effective if made in writing signed by an authorized officer of NIGHT PROGRAMMERS TECHNOLOGIES . All refunds will be made onto the original mode of payment.`
    },
    {
        line:
            `5. OFFICE FOREIGN ASSETS CONTROL (OFAC) SANCTIONED COUNTRIES. NIGHT PROGRAMMERS TECHNOLOGIES will not trade with or provide any services to individuals and companies owned or controlled by, or acting for or on behalf of, OFAC targeted countries AND individuals, groups, and entities, such as terrorists and narcotics traffickers designated under the OFAC programs that are not country specific`
    },
    {
        line:
            `6. REPRESENTATIONS BY YOU. By visiting the Site, you represent, warrant and covenant that (a) you are at least 18 years old; (b) that all materials of any kind submitted by you to NIGHT PROGRAMMERS TECHNOLOGIES through the Site or for inclusion on the Site will not plagiarize, violate or infringe upon the rights of any third-party including trade secret, copyright, trademark, trade dress, privacy, patent, or other personal or proprietary rights. The customer using the website who are Minor /under the age of 18 shall not register as a User of the website and shall not transact on or use the website.`
    },
    {
        line:
            `7. PERMITTED USE. You agree that you are only authorized to visit, view and to retain a copy of pages of this Site for your own personal use, that you shall not duplicate, download, publish, modify or otherwise distribute the material on this Site for any purpose other than for personal use, unless otherwise specifically authorized by NIGHT PROGRAMMERS TECHNOLOGIES to do so. You also agree not to deep-link to the site for any purpose, unless specifically authorized by NIGHT PROGRAMMERS TECHNOLOGIES to do so. The content and software on this Site is the property of NIGHT PROGRAMMERS TECHNOLOGIES. The cardholder must retain a copy of transaction records and Merchant policies and rules.`
    },
    {
        line:
            `8. YOUR ACCOUNT. If you use NIGHT PROGRAMMERS TECHNOLOGIES Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your account from any devices, and you agree to accept responsibility for all activities that occur under your account or password. The Site shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this section.`
    },
    {
        line:
            `NO COMMERCIAL USE. This Site may not be used by you for any commercial purposes such as to conduct sales of merchandise or services of any kind. You must obtain NIGHT PROGRAMMERS TECHNOLOGIES ’s prior written consent to make commercial offers of any kind on the Site, whether by advertising, solicitations, links, or any other form of communication. NIGHT PROGRAMMERS TECHNOLOGIES  will investigate and take appropriate legal action against anyone who violates this provision, including without limitation, removing the offending communication from the Site and barring such violators from use of the Site.`
    },
    {
        line:
            `LINKS AND SEARCH RESULTS. The Site may automatically produce search results that reference and/or link to third party sites throughout the World Wide Web. NIGHT PROGRAMMERS TECHNOLOGIES  has no control over these sites or the content within them. NIGHT PROGRAMMERS TECHNOLOGIES  does not guarantee, represent or warrant that the content contained in the sites is accurate, legal and/or inoffensive. NIGHT PROGRAMMERS TECHNOLOGIES  does not endorse the content of any third party site, nor does it make any representation or warranty about these sites, including that they will not contain viruses or otherwise impact your computer. By using the Site to search for or link to another site, you agree and understand that you may not make any claim against NIGHT PROGRAMMERS TECHNOLOGIES  for any damages or losses, whatsoever, resulting from your use of the Site to obtain search results or to link to another site. If you have a problem with a link from the Site, you may notify us at support@pocktapp.in`
    },
    {
        line:
            `COPYRIGHT POLICY. NIGHT PROGRAMMERS TECHNOLOGIES  may terminate the privileges of any user who uses this Site to unlawfully transmit copyrighted material without a license, express consent, valid defence or fair use exemption to do so. If you submit information to this Site, you warrant that the information does not infringe the copyrights or other rights of third parties.`
    },
    {
        line:
            `INTELLECTUAL PROPERTY. Although NIGHT PROGRAMMERS TECHNOLOGIES  is not responsible for the content, quality or accuracy of data provided by users, compilations of such data, text, information and other materials made available to users through NIGHT PROGRAMMERS TECHNOLOGIES ’s system. The On-line Materials are NIGHT PROGRAMMERS TECHNOLOGIES ’s intellectual property, and are protected by Indian and international intellectual property laws. The On-line Materials may not be copied or redistributed either in whole or in part without prior written consent of NIGHT PROGRAMMERS TECHNOLOGIES , except as expressly and specifically permitted under these Terms of Use.`
    },
    {
        line:
            `The On-line Materials are and will remain the exclusive property of NIGHT PROGRAMMERS TECHNOLOGIES. All rights, titles and interests in and to the On-line Materials will be and remain vested solely in NIGHT PROGRAMMERS TECHNOLOGIES. Under no circumstances will you have any right, directly or indirectly, to own, use, copy, loan, sell, rent, lease, license, sublicense, redistribute, assign or otherwise convey the On- line Materials, or any rights thereto, except as expressly and specifically provided in the Terms of Use. Nothing in these Terms of Use will convey to you any right, title or interest, except that of a license with the express rights and subject to all limitations herein. Nothing in these Terms of Use grants you the right, directly or indirectly, to use the On-line Materials to create a product for resale or to use the On-line Materials in any way that competes with NIGHT PROGRAMMERS TECHNOLOGIES.`
    },
    {
        line:
            `You acknowledge and agree that NIGHT PROGRAMMERS TECHNOLOGIES will own all rights, titles and interests in and to any copy, translation, modification, adaptation, derivative work or improvement of the On- line Materials made by or for you. At NIGHT PROGRAMMERS TECHNOLOGIES ’s request, you must execute, or obtain the execution of, any instrument that may be necessary to assign these rights, titles or interests to NIGHT PROGRAMMERS TECHNOLOGIES or perfect these rights, titles or interests in NIGHT PROGRAMMERS TECHNOLOGIES ’s name. DISCLAIMER OF WARRANTY, LIMITATION OF DAMAGES. NIGHT PROGRAMMERS TECHNOLOGIES MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTY OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR OTHER VIOLATION OF RIGHTS IN RELATION TO THE AVAILABILITY, ACCURACY, VALIDITY, RELIABILITY OR CONTENT OF THESE PAGES AND/OR THE SITE. NIGHT PROGRAMMERS TECHNOLOGIES ALSO DOES NOT MAKE ANY REPRESENTATION OR WARRANTY REGARDING THE ACCURACY OR RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION THAT IS SUBMITTED, DISPLAYED OR UPLOADED THROUGH THE SITE BY ANY USER. NIGHT PROGRAMMERS TECHNOLOGIES SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR FOR BUSINESS INTERRUPTION ARISING OUT OF THE USE OF OR INABILITY TO USE THIS SITE, EVEN IF NIGHT PROGRAMMERS TECHNOLOGIES  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS OF LIABILITY, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THE LIABILITY OF NIGHT PROGRAMMERS TECHNOLOGIES WOULD IN SUCH CASE BE LIMITED TO THE GREATEST EXTENT OF LIABILITY PERMITTED BY LAW.`
    },
    {
        line:
            `VIOLATION OF TERMS OF USE. You understand and agree that in NIGHT PROGRAMMERS TECHNOLOGIES ’s sole discretion, and without prior notice, NIGHT PROGRAMMERS TECHNOLOGIES  may terminate your access to the Site, or exercise any other remedy available and remove any unauthorized user information, if NIGHT PROGRAMMERS TECHNOLOGIES  believes that the information you provide has violated or is inconsistent with these Terms of Use, or violates the rights of NIGHT PROGRAMMERS TECHNOLOGIES , or any third party, or violates the law. You agree that monetary damages may not provide a sufficient remedy to NIGHT PROGRAMMERS TECHNOLOGIES  for violations of these Terms of Use and you consent to injunctive or other equitable relief for such violations. NIGHT PROGRAMMERS TECHNOLOGIES  may release user information about you if required by law or subpoena.`
    },
    {
        line:
            `INDEMNITY. You agree to indemnify and hold NIGHT PROGRAMMERS TECHNOLOGIES , its subsidiaries, affiliates, officers, agents and other partners and employees, harmless from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of or relating to your use of the Site, including also your use of the Site to provide a link to another site or to upload content or other information to the Site, or your breach of the Terms of Use.`
    },
    {
        line:
            `LICENCE GRANTED TO YOU. By providing materials to NIGHT PROGRAMMERS TECHNOLOGIES, including by submitting or uploading content or materials for use on the Site you represent and warrant that you or the owner of all rights to such content or materials has expressly granted NIGHT PROGRAMMERS TECHNOLOGIES an irrevocable world-wide right in all languages and in perpetuity to use and exploit all or any part of the content and materials provided by you. NIGHT PROGRAMMERS TECHNOLOGIES may publish and distribute any such submitted content or materials at its sole discretion by any method now existing or later developed. You agree that you shall waive all claims and have no recourse against NIGHT PROGRAMMERS TECHNOLOGIES for any alleged or actual infringement or misappropriation of any proprietary rights in any communication, content or material submitted to NIGHT PROGRAMMERS TECHNOLOGIES. Any communication or materials you send to NIGHT PROGRAMMERS TECHNOLOGIES will be treated as non- confidential and non-proprietary and may be disseminated or used by NIGHT PROGRAMMERS TECHNOLOGIES  for any purpose, including, but not limited to, developing, creating, manufacturing or marketing products or services.`
    },
    {
        line:
            `ADVERTISING. The Site may contain advertisements and/or sponsorships. The advertisers and/or sponsors that provide these advertisements and sponsorships are solely responsible for insuring that the materials submitted for inclusion on the Site are accurate and comply with all applicable laws. NIGHT PROGRAMMERS TECHNOLOGIES is not responsible for the acts or omissions of any advertiser or sponsor.`
    },
    {
        line:
            `SEVERABILITY. If any provision of the Terms of Use is held to be invalid, void or unenforceable, the remaining provisions shall nevertheless continue in full force and effect.`
    },
    {
        line:
            `Headings & section titles in this Agreement are for convenience and do not define, limit, or extend any provision of this Agreement.`
    }
]



export const cookiedata = [
    {
        line:

            `A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when Platforms set or access cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or not function properly. A list of the type of cookies we use is as follows;`
    },
    {
        line:
            `1. Strictly necessary cookies. These are cookies that are required for the operation of our Platform. They include, for example, cookies that enable you to log into secure areas of our Platform, use a shopping cart or make use of e-billing services.`
    },
    {
        line:
            `2. Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our Platform when they are using it. This helps us to improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily.`
    },
    {
        line:
            `3. Functionality cookies. These are used to recognize you when you return to our Platform. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).`
    },
    {
        line:
            `4. Targeting cookies. These cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third-parties for this purpose.`
    },
    {
        line:
            `Please note that third-parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Platform.`
    },
    {
        line:
            `GOOGLE ANALYTICS`
    },
    {
        line:
            `The information generated by the cookie about your use of the Platform, including your IP address, may be transmitted to and stored by Google on servers in the United States. Google may use this information for the purpose of evaluating your use of the Platform, compiling reports on Platform activity for us and providing other services relating to Platform activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. By using this Platform, you consent to the processing of data about you by Google in the manner and for the purposes set out above.

            The Google Platform contains further information about Analytics and a copy of Google's privacy policy pages`
    },
    {
        line:
            `GOOGLE ADSENSE`
    },
    {
        line:
            `Google AdSense is a tool that allows Platform publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google uses cookies and tracking technology to deliver ads personalized to a Platform user/visitor. In this regard the following terms are specified to the Users:`
    },
    {
        line:
            `a. Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our Platform or other Platforms.`
    },
    {
        line:
            `b. Google's use of advertising cookies enables us and our partners to serve advertisements to you based on their visit to our Platform and/or other Platforms on the Internet.`
    },
    {
        line:
            `c. You may opt-out of personalized advertising by visiting Ads Settings.`
    },
    {
        line:
            `d. All advertisements of third parties on our Platform are for informative purposes only and neither the Platform nor the Firm guarantees or bears liability for the authenticity of the advertisements.`
    },
    {
        line:
            `e. At no point will the Firm permit its competitors to advertise on the Platform.

            You may visit the links in the advertisements at your own risk or choose to not accept the cookies permitting third parties to display their advertisements.`
    },

]


export const privacydata = [
    {
        line:

            `The creator of this Privacy Policy ensures a steady commitment to your privacy with regard to the protection of your invaluable information that you may share across this Platform.`
    },
    {
        line:
            `To provide you with our uninterrupted use of the Platform, we may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of your privacy, we provide this notice explaining our information collection and disclosure policies, and the choices You make about the way your information is collected and used.`
    },
    {
        line:
            `We may process the personal data of a user only in accordance with the terms specified in this Privacy Policy and applicable data protection laws. Personal data may be processed based on the consent provided by the user or for certain legitimate uses. Consent requests will be accompanied or preceded by a notice, informing the user of the personal data being processed, the purpose of processing, the method to withdraw consent, and the procedure to make a complaint to the relevant authorities. We may continue to process personal data until the user withdraws consent. The consent obtained shall be free, specific, informed, unconditional, and unambiguous, requiring a clear affirmative action from the user. The withdrawal of consent by the user will not affect the legality of processing based on consent before withdrawal. The user has the right to withdraw consent at any time, and the consequences of withdrawal shall be borne by the user.`
    },
    {
        line:
            `INFORMATION WE COLLECT `
    },
    {
        line:
            `a. name, age, gender, photo, address, phone number, e-mail id, your contacts`
    },
    {
        line:
            `b. Details of Platform usage for analytics.`
    },
    {
        line:
            `c. your device details such as device identifier, internet bandwidth, mobile device model, browser plug-ins, and cookies or similar technologies that may identify your time spent, IP address and location.`
    },
    {
        line:
            `This privacy policy also applies to data we collect from users who are not registered as members of this Platform, including, but not limited to, browsing behavior, pages viewed etc.`
    },
    {
        line:
            `We only collect and use such information collected from you that we consider necessary for achieving a seamless, efficient, and safe experience in using the platform, customized to your needs including:`
    },
    {
        line:
            `a. To enable the provision of services opted for by you;`
    },
    {
        line:
            `b. To enable the viewing of content in your interest;`
    },
    {
        line:
            `c. To communicate the necessary account and service-related information from time to time;`
    },
    {
        line:
            `d. To allow you to receive quality customer care services and data Collection;`
    },
    {
        line:
            `e. To comply with applicable laws, rules and regulations;`
    },
    {
        line:
            `Where any service requested by You involves a third party, such information as is reasonably necessary to carry out Your service request may be shared with such third party.`
    },
    {
        line:
            `We also use your contact information to send you offers based on your interests and prior activity and also to show the content preferred by you. We shall immediately delete all such information upon withdrawal of your consent for the same through the ‘unsubscribe’ button or through an email to be sent to “support@pocktapp.in”.`
    },
    {
        line:
            `To the extent possible, we provide you with an option of not divulging any specific information that you wish for us not to collect, store, or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the Platform.`
    },
    {
        line:
            `Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login-related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.`
    },
    {
        line:
            `OUR USE OF YOUR INFORMATION`
    },
    {
        line:
            `The information provided by you shall be used to provide and improve the service for you and all users.`
    },
    {
        line:
            `a. To provide you with services on your reques`
    },
    {
        line:
            `b. For maintaining an internal record.`
    },
    {
        line:
            `c. For enhancing the Services provided.`
    }, {
        line:
            `d. For maintaining record under the legal and statutory provisions`
    },
    {
        line:
            `For more details about the nature of such communications, please refer to our Terms of Service.`
    },




    {
        line:
            `We use your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information, and make further services available to you.`
    }, {
        line:
            `We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.`
    }, {
        line:
            `Information collected via Our server logs includes users' IP addresses and the pages visited will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.`
    }, {
        line:
            `HOW INFORMATION IS COLLECTED`
    },
    {
        line:
            `Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to explain the purpose of the collection of said personal information, pending the fulfillment of which you shall not be mandated to disclose any information whatsoever.`
    },
    {
        line: `We will collect and use your personal information solely to fulfill those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfillment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.`
    }, {
        line: `Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.`
    }, {
        line: "EXTERNAL LINKS ON THE PLATFORM"
    }, {
        line: `The Platform may include advertisements, hyperlinks to other Platforms or resources. We have no control over any other Platform or resources or contents available on these other Platforms, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/services or other materials on or available from such Platform or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any

        advertising, services or other materials on, or available from, such Platforms. These external third-party Platforms and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter their Platform and review its privacy policy.`
    },
    {
        line: `COOKIES`
    }, {
        line: `Please refer to our Cookies Policies here.`
    },
    {
        line: `YOUR RIGHTS`
    }, {
        line: `Unless subject to an exemption, you have the following rights concerning your data:`
    }, {
        line:
            `a. The right to request a copy of your data which we hold about you;`
    }, {
        line:
            ` b. The right to request for any correction to any personal data if it is found to be inaccurate or out of date;`
    }, {
        line:
            `  c. The right to withdraw Your consent to the processing at any time;`
    }, {
        line:
            `d. The right to object to the processing of personal data;`
    }, {
        line:
            `e. The right to complain about a supervisory authority.`
    }, {
        line:
            ` f. The right to obtain information as to whether personal data are transferred to a third country or an international organization.`
    }, {
        line:
            ` Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.`
    },
    {
        line: "COMPLIANCES"
    }, {
        line: "i. This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.",
    },
    {
        line: "ii. This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional Platform.",
    },
    {
        line: "iii. This Privacy Policy is formulated in compliance with the provisions of THE DIGITAL PERSONAL DATA PROTECTION ACT, 2023, and any other relevant data protection laws applicable to the processing of personal data. The policy outlines the principles and procedures for collecting, processing, and safeguarding personal data in adherence to the statutory requirements set forth by THE DIGITAL PERSONAL DATA PROTECTION ACT, 2023. Users are encouraged to review and understand this Privacy Policy in conjunction with the applicable data protection laws.",
    },
    {
        line: "CONFIDENTIALITY"
    },
    {
        line: "You further acknowledge that the Platform may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.",
    },
    {
        line: "OTHER INFORMATION COLLECTOR"
    },
    {
        line: "Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of third parties, you are subject to ask questions before you disclose your personal information to others.",
    },
    {
        line: "OUR DISCLOSURE OF YOUR INFORMATION",
    },
    {
        line: "Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.",
    },
    {
        line: "As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:",
    },
    {
        line: "a. External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.",
    },
    {
        line: "b. Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.",
    },







    {
        line: "ACCESSING, REVIEWING AND CHANGING YOUR PROFILE",
    }, {
        line: "You can review and change the information you submitted except contact. An option for facilitating such change shall be present on the Platform and such change shall be facilitated by the User. If you change any information, we may or may not keep track of your old information. We will not retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems, and enforce our terms and conditions. Such prior information shall be completely removed from our databases, including stored ‘back up’ systems. If you believe that any information, we are holding on to you is incorrect or incomplete, or to remove your profile so that others cannot view it, the User needs to remediate, and promptly correct any such incorrect information.",
    }, {
        line: "SECURITY",
    }, {
        line: `We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Firm. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.

        However, as effective as encryption technology is, no security system is impenetrable.
        
        Our Firm cannot guarantee the security of our database, nor can we guarantee that information you provide won’t be intercepted while being transmitted to the Firm over the Internet.`,
    }, {
        line: "SEVERABILITY",
    }, {
        line: "Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.",
    }, {
        line: "AMENDMENT",
    }, {
        line: `Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.`
    }, {
        line: ` CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUEST`
    }, {
        line: `To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to “support@pocktapp.in”`
    }, {
        line: `DISCLAIMER`
    }, {
        line: `We do not store any account related information or any credit / debit card details. We shall not be liable for any loss or damage sustained by Users as a result of any disclosure (inadvertent or otherwise) of any information concerning the User's account, credit cards or debit cards in the course of any online transactions or payments made for any products and/or services offered through the Platform.

        In case any Personal Information is shared by you with us, which is not requested by us during registration, (whether mandatorily or optionally), we will not be liable for any information security breach or disclosure in relation to such information.`
    }, {
        line: `DATA PROTECTION OFFICER/ GRIEVANCE OFFICER`
    }, {
        line: `In case you have any complaints and/or grievances in relation to the processing of your Personal Information you can send your complaints via e-mail grievance@nightprogrammers.in.`
    }
]


export const refunddata = [

    {
        line: `The online payment once received will not be refunded for successful bill payment services.`
    },
    {
        line: `Sometime, the payment could b successful, but the bill payment failed. Some of the common reasons for failed bill payments include:`
    },
    {
        line: `i. Incorrect authenticator details like account no, customer ID etc.`
    }, {
        line: `ii. Late payment fee not added to the outstanding bill amount.`
    }, {
        line: `iii. Technical issues at the biller's end.`
    },
    , {
        line: `By default all failure Transactions are automatically refunded as per the standard policy of the respective banks. However for the same to reflect in your account it may take specific time as per the standard policy of the respective bank. Generally refunds will be reflected within a standard number of Days in Case of Net Banking/Debit Card Transaction &a standard number of Days (Next Billing Cycle) for Credit Card Transactions. For any further details on the same, we request you to please contact the respective banks. We NIGHT PROGRAMMERS TECHNOLOGIES PRIVATE LIMITED shall not be held liable for the same.`
    }, {
        line: `We request all Tax payers to kindly verify the Tax bill Details carefully before making any payment.`
    }, {
        line: `We shall not entertain any refund requests if cancellation of subscription is in violation of this policy or its Terms of Service or if we terminate the user account for violation of its Terms of Service.`
    },

    {
        line: `The bill payments once made by you cannot be cancelled for a refund. An order may be cancelled before deployment due to any of the following reasons:`
    },
    {
        line: `1. The specific product/model is out of stock;`
    }, {

        line: `2. On merchants request; or`
    }, {

        line: `3. Any other reason beyond the our control.`
    }, {
        line: `A refund/cancellation can be refused if the following conditions are met:`
    }, {

        line: `1. The services already taken;`
    }, {

        line: `2. The product is completely broken and unusable or with scratches or incomplete as per the seller claims from the customer; or`

    }, {
        line:`3. There are outstanding on the merchants account which have not been paid.`
    }
]